import httpUtil from '../utils/httpUtil'

/**更新转介绍规则设置 */

export const updateSetting = params => httpUtil.post("/api/crmPc/partner/updateSetting", params);

/**查询转介绍规则设置 */

export const querySetting = params => httpUtil.post("/api/crmPc/partner/querySetting", params);

/**查询转介绍规则设置 */

export const queryPartnerList = params => httpUtil.post("/api/crmPc/partner/list", params);

/**转介绍合作伙伴列表 */

export const employeeList = params => httpUtil.post("/api/crmPc/partner/employeeList", params);

/**转介绍合作伙伴详情 */

export const employeeDetails = params => httpUtil.post("/api/crmPc/partner/employeeDetails", params);

/**转介绍公司统计详情 */

export const companyDetails = params => httpUtil.post("/api/crmPc/partner/companyDetails", params);


/**转介绍公司统计详情 */

export const queryInvitationCode= params => httpUtil.post("/api/crmPc/partner/image", params);



/**合作伙伴-客户列表 */

export const customerList= params => httpUtil.post("/api/crmPc/partner/customerList", params);

/**解除合作关系 */

export const dismiss= params => httpUtil.post("/api/crmPc/partner/dismiss", params);


/**提现列表 */

export const withdrawalsList = params => httpUtil.post("/api/crmPc/partner/withdrawalsList", params);

/**提现审核 */

export const withdrawalsReview = params => httpUtil.post("/api/crmPc/partner/withdrawalsReview", params);

/**提现审核 */

export const details = params => httpUtil.post("/api/crmPc/partner/details", params);
